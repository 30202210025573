import { type ReactNode, useRef } from 'react';
import type { GridResponse } from '~/schemas/grid';
import { contextFactory } from '~/utils/contextFactory';

export const [GridChunkCacheContext, useGridChunkCacheContext] = contextFactory<Map<string, GridResponse>>();

export const GridChunkCacheProvider = ({ children }: { children: ReactNode }) => {
  const value = useRef(new Map<string, GridResponse>());
  return <GridChunkCacheContext.Provider value={value.current}>{children}</GridChunkCacheContext.Provider>;
};
